.#{$namespace}checkbox {
    position: relative;
    height: 37px;
    line-height: 37px;
    border: 2px solid $color-grey;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    $root: &;

    @media #{$phone-lg-only} {
        height: 70px;
        line-height: 66px;
        font-size: 15px;
    }

    &__check {
        display: none;

        &:checked + #{$root}__custom-check {
            &:after {
                background: $color-orange;

            }
        }

        &:disabled ~ #{$root}__label {
            opacity: .3;
            cursor: default;
        }
    }

    &__custom-check {
        position: absolute;
        top: 50%;
        left: 6px;
        margin-top: - (26px / 2);
        display: block;
        width: 26px;
        height: 26px;
        vertical-align: middle;
        border-radius: 50%;
        border: 2px solid $color-grey;
        box-sizing: border-box;

        @media #{$phone-lg-only} {
            width: 44px;
            height: 44px;
            left: 11px;
            margin-top: - (44px / 2);
        }

        &:after {
            content: '';
            width: 14px;
            height: 14px;
            display: block;
            margin: (22px - 14px) / 2 auto;
            border-radius: 50%;
            background: $color-light-grey;
            transition: background-color .1s linear;

            @media #{$phone-lg-only} {
                width: 24px;
                height: 24px;
                margin: (40px - 24px) / 2 auto;
            }
        }
    }

    &__label {
        cursor: pointer;
        padding: 0 18px 0 6px + 26px + 6px;
        z-index: 2;

        @media #{$phone-lg-only} {
            padding: 0 40px 0 13px + 44px + 13px;
        }
    }

    & .#{$namespace}icon {
        position: absolute;
    }
}