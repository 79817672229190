/**
 * Configuration files.
 */
@import 'configs/fonts';
@import 'configs/variables';
@import 'configs/breakpoints';
@import 'configs/functions';
@import 'configs/mixins';


/**
 * Vendor SCSS files.
 */
@import './vendors/autocomplete';


/**
 * Icons
 */
@import 'images/icons/icons';

/**
 * Atoms
 */
@import 'atoms/button/button';
@import 'atoms/input/input';
@import 'atoms/checkbox/checkbox';
@import 'atoms/select/select';
@import 'atoms/counter/counter';
@import 'atoms/icon/icon';
@import 'atoms/color/color';
@import 'atoms/loader/loader';
@import 'atoms/breakpoint/breakpoint';
@import 'atoms/field-label/field-label';

/**
 * Molecules
 */
@import 'molecules/widget/widget';
@import 'molecules/result/result';
@import 'molecules/modal/modal';
@import 'molecules/dropdown/dropdown';

/**
 * Organisms
 */
@import 'organisms/widget-simple/widget-simple';
@import 'organisms/widget-advanced/widget-advanced';
@import 'organisms/widget-filters/widget-filters';
@import 'organisms/widget-results/widget-results';
@import 'organisms/widget-topjobs/widget-topjobs'; // must be AFTER widget-results (cascading styles)
@import 'organisms/pagination/pagination';
@import 'organisms/carousel/carousel';
@import 'organisms/newsticker/newsticker';

/**
 * Layouts styles.
 */
