.#{$namespace}widget-filters {
    background: $color-light-grey;
    padding: 25px 25px 35px 25px;
    $root: &;

    @media #{$tablet-up} {
        padding: 25px 20px 35px 20px;
    }

    &__container {
        position: relative;
        display: flex;
        flex-flow: row wrap;
        margin: 15px -15px;
        max-height: 425px;
        overflow: hidden;
        transition: max-height .3s linear, margin .3s linear;

        @media #{$phone-lg-up} {
            max-height: 660px;
        }

        @media #{$tablet-up} {
            max-height: 300px;
            padding-bottom: 0;
        }

        &--collapsed {
            max-height: 0 !important;
            margin: 0 -15px !important;
            padding: 0 !important;
        }
    }

    &__toggle-arrow {
        width: 34px;
        height: 34px;
        margin-right: 10px;
        display: inline-block;
        transition: transform .2s ease-in;
        cursor: pointer;

        @media #{$phone-lg-up} {
            width: 42px;
            height: 42px;
        }

        @media #{$tablet-up} {
            width: 26px;
            height: 26px;
        }

        & .#{$namespace}icon {
            width: 100%;
            height: 100%;
            fill: $color-base;
        }

        &--toggle {
            transform: rotate(180deg);
        }
    }

    &__headline {
        font-size: 18px;
        position: relative;
        top: -10px;

        @media #{$phone-lg-up} {
            font-size: 23px;
        }

        @media #{$tablet-up} {
            font-size: 16px;
            top: -5px;
        }
    }

    &__filter-wrapper {
        padding: 5px 15px;
        max-width: 100%;
        box-sizing: border-box;
        overflow: hidden;

        &--profession + & {
            @media #{$tablet-up} {
                margin-left: -120px;
            }
        }
    }

    &__field-wrapper {
        display: flex;
        background: $color-white;
        border-radius: 999px;
        border: 2px solid transparent;
        flex-basis: 0;
        flex-grow: 1;

        > #{$root}__filter {
            margin: -2px;
            width: calc(100% + 4px);
        }
    }

    &__filter {
        border: 2px solid $color-grey;
        border-radius: 9999px;
        height: 41px;
        font-size: 11px;
        line-height: 37px;
        font-weight: 700;
        color: $color-base;
        display: inherit;

        @media #{$phone-lg-only} {
            height: 70px;
            line-height: 66px;
            font-size: 15px;
        }

        &.cs-select {
            padding-right: 40px;

            @media #{$tablet-up} {
                padding-right: 30px;
            }
        }

        &::placeholder {
            font-family: $base-font-family;
            color: $color-grey;
            font-style: italic;
        }

        &.cs-checkbox {
            height: 37px;

            @media #{$phone-lg-only} {
                height: 66px;
            }
        }

        &--profession {
            margin-right: 120px;
            min-width: 250px;
            line-height: 16px;
            padding-top: 11px;
            padding-bottom: 11px;
        }
    }

    &__label {
        font-size: 10px;
        padding: 0 0 0 20px;
        font-weight: 700;
        display: block;
        text-transform: uppercase;

        @media #{$phone-lg-only} {
            font-size: 13px;
            padding: 0 0 5px 42px;
        }
    }

    &__clear-wrapper {
        flex-grow: 1;
        text-align: right;
        padding: 10px 15px 0 0;
        align-self: flex-end;
    }

    &__clear {
        cursor: pointer;
        transition: color .2s linear;

        @media #{$phone-lg-only} {
            font-size: 23px;
        }

        .no-touch &:hover {
            color: $color-dark-grey;

            & .#{$namespace}icon {
                fill: $color-dark-grey;
            }
        }

        & .#{$namespace}icon {
            display: block;
            float: right;
            width: 16px;
            height: 21px;
            margin-left: 15px;
            fill: $color-base;
            transition: fill .2s linear;

            @media #{$phone-lg-only} {
                width: 24px;
                height: 32px;

            }
        }
    }
}