/**
 * Namespace string for every component.
 */
$namespace: 'cs-';

/**
 * Base typography configuration.
 */
$base-font-size: 14px;
/**
 * Base font-family that uses the best looking build-in fonts:
 * - Roboto for Android devices,
 * - San-Francisco for Apple devices,
 * - Arial for others.
 *
 * For more information about the mess below see a great resource:
 * @see http://furbo.org/2015/07/09/i-left-my-system-fonts-in-san-francisco/
 */
$base-font-family: 'Open Sans', system, 'San Francisco', -apple-system, BlinkMacSystemFont, '.SFNSDisplay-Regular', 'Open Sans', sans-serif;

/**
 * Colors
 */
$color-base: #737373;
$color-grey: #a7a7a7;
$color-light-grey: #cdcdcd;
$color-dark-grey: #3f3f3f;
$color-orange: #f36523;
$color-orange-light: mix($color-orange, #fff, 30);
$color-orange-dark: mix($color-orange, #000, 90);
$color-white: #fff;

/**
 * SVG Icons
 */


/**
 * Other
 */
$container-width: 1040px;
