.#{$namespace}widget {
    position: relative;
    font-family: $base-font-family;
    width: 100%;
    box-sizing: border-box;
    color: $color-base;

    &__container {
        max-width: $container-width;
        margin: 0 auto;
    }

    &__field-wrapper {
        position: relative;
        display: inherit;

        &--inline-flex {
            display: inline-flex;
        }
    }

    a {
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    }
}
