.#{$namespace}carousel {
    position: relative;
    overflow: hidden;
    border-width: 4px;
    border-style: solid;

    &__slides-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        position: relative;
        transition: margin-left .5s ease-in-out;
    }

    &__slide {
        flex-shrink: 0;
        margin-right: 10px;
    }

    &__buttons {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__button {
        cursor: pointer;
        position: relative;
        width: 50px;
        height: 100%;
        border: none;
        z-index: 105;
        margin: 0;
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: .7;
        background: none;
        transition: opacity .1s linear, background-color .2s linear;

        &:focus {
            outline: none;
        }

        ~ .#{$namespace}icon {
            transition: none;
        }

        &--disabled {
            opacity: .2;
            cursor: default;

            & .#{$namespace}icon {
                fill: $color-light-grey !important;
            }

            .no-touch &:hover {

                & .#{$namespace}icon {
                    fill: $color-light-grey !important;
                }
            }
        }
    }

    &__bullets {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;

        @media #{$tablet-up} {
            height: 50px;
        }
    }

    &__bullet {
        width: 7px;
        height: 7px;
        display: inline-block;
        border: 4px solid $color-light-grey;
        border-radius: 100%;
        margin: 0 5px;
        cursor: pointer;
        transition: width .1s linear, height .1s linear;
        z-index: 100;

        &--active {
            width: 0;
            height: 0;
            transition: width .1s linear, height .1s linear, border-color .1s linear;
        }
    }
}