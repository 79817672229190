.#{$namespace}loader {

    position: relative;
    min-height: 50px;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, .8);
        z-index: 10;
    }

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -10px;
        margin-left: -10px;
        width: 20px;
        height: 20px;
        background-image: url('data:image/svg+xml;base64,' + $icon-loader-64);
        background-size: 20px;
        background-position: center;
        background-repeat: no-repeat;
        animation: rotation 1s infinite linear;
        z-index: 11;
    }
}

@keyframes rotation {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}