/**
 * Typical clearfix mixin.
 * Usage:
 * .foo {
 *   @include clearfix();
 * }
 */
@mixin clearfix() {
    zoom: 1;

    &:before, &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }
}

/**
 * Mixin converting pixel values to REM with pixel fallback.
 * Usage:
 * h1 {
 *   @include rem(marign, 0 auto 20px);
 * }
 * will convert to:
 * h1 {
 *   marign: 0 auto 20px;
 *   marign: 0 auto 1.42857rem;
 * }
 */
@mixin rem($property, $values) {
  // Create a couple of empty lists as output buffers.
  $px-values: ();
  $rem-values: ();

  // Loop through the $values list
  @each $value in $values {
    // For each property value, if it's in rem or px, derive both rem and
    // px values for it and add those to the end of the appropriate buffer.
    // Ensure all pixel values are rounded to the nearest pixel.
    @if $value == 0 or $value == 0px {
      // 0 -- use it without a unit
      $px-values: join($px-values, 0);
      $rem-values: join($rem-values, 0);
    } @else if type-of($value) == number and (unit($value) == px) {
      // px value given - calculate rem value from base-font-size
      $new-rem-value: $value / $base-font-size;
      $px-values: join($px-values, round($value));
      $rem-values: join($rem-values, #{$new-rem-value}rem);
    } @else {
      // unitless value - use those directly as rem and calculate the px-fallback
      $px-values: join($px-values, $value);
      $rem-values: join($rem-values, $value);
    }
  }

  // output the converted rules
  #{$property}: $px-values;
  #{$property}: $rem-values;
}

@mixin iconBg($icon, $color: #000000 ) {
    $icon: changeIconColor($icon, $color);
    $str: "data:image/svg+xml;base64,";
    background-image: url($str + $icon );
    background-repeat: no-repeat;
}


@mixin iconBgMultiple($list) {
    $icon: '';
    $color: '';
    $str: '';
    @each $item in $list {
        @if(type-of($item) == 'string') {
            $icon: $item;
        } @else {
            $color: $item;
            $icon: changeIconColor($icon, $color);
            $str: $str + url("data:image/svg+xml;utf8," + $icon ) + ', ';
        }
    }
    background-image: #{str-slice($str, 1, str-length($str) - 2)};
    background-repeat: no-repeat;
}
