/**
 * Here we define the lower and upper bounds for each media size
 */
$phone-xs-range: (0, 23.13em); /* 0, 370px */
$phone-range: (23.19em, 29.934em); /* 371, 479px */
$phone-xl-range: (30em, 47.938em); /* 480, 767px */
$tablet-range: (48em, 63.938em); /* 768px, 1023px */
$laptop-range: (64em, 90em); /* 1024px, 1440px */
$desktop-range: (90.063em, 120em); /* 1441px, 1920px */
$tv-range: (120.063em); /* 1921px */

/**
 * We use these functions to get the ranges for the media queries variables.
 */
@function lower-bound($range){
    @if length($range) <= 0 {
        @return 0;
    }
    @return nth($range,1);
}

@function upper-bound($range) {
    @if length($range) < 2 {
        @return 999999999999;
    }
    @return nth($range, 2);
}

/**
 * Media Queries
 */
$screen: "only screen";

$landscape: "#{$screen} and (orientation: landscape)";
$portrait: "#{$screen} and (orientation: portrait)";

$phone-xs-up: $screen;
$phone-xs-only: "#{$screen} and (max-width: #{upper-bound($phone-xs-range)})";

$phone-up: "#{$screen} and (min-width:#{lower-bound($phone-range)})";
$phone-only: "#{$screen} and (min-width:#{lower-bound($phone-range)}) and (max-width:#{upper-bound($phone-range)})";

$phone-lg-up: "#{$screen} and (min-width:#{lower-bound($phone-xl-range)})";
$phone-lg-only: "#{$screen} and (min-width:#{lower-bound($phone-xl-range)}) and (max-width:#{upper-bound($phone-xl-range)})";

$tablet-up: "#{$screen} and (min-width:#{lower-bound($tablet-range)})";
$tablet-only: "#{$screen} and (min-width:#{lower-bound($tablet-range)}) and (max-width:#{upper-bound($tablet-range)})";

$laptop-up: "#{$screen} and (min-width:#{lower-bound($laptop-range)})";
$laptop-only: "#{$screen} and (min-width:#{lower-bound($laptop-range)}) and (max-width:#{upper-bound($laptop-range)})";

$desktop-up: "#{$screen} and (min-width:#{lower-bound($desktop-range)})";
$desktop-only: "#{$screen} and (min-width:#{lower-bound($desktop-range)}) and (max-width:#{upper-bound($desktop-range)})";

$tv-up: "#{$screen} and (min-width:#{lower-bound($tv-range)})";
$tv-only: "#{$screen} and (min-width:#{lower-bound($tv-range)}) and (max-width:#{upper-bound($tv-range)})";

/**
 * Example usage:
 *
 * @media #{$phone-xs-up} { }
 * @media #{$phone-xs-only} { }
 *
 * @media #{$phone-up} { }
 * @media #{$phone-only} { }
 *
 * @media #{$phone-lg-up} { }
 * @media #{$phone-lg-only} { }
 *
 * @media #{$tablet-up} { }
 * @media #{$tablet-only} { }
 *
 * @media #{$laptop-up} { }
 * @media #{$laptop-only} { }

 * @media #{$desktop-up} { }
 * @media #{$desktop-only} { }

 * @media #{$tv-up} { }
 * @media #{$tv-only} { }
 */
