.#{$namespace}widget-results {
    padding: 0 20px;

    &__results {
        list-style-type: none;
        margin: 0;
        padding: 0;
        background: #fff;
        overflow-y: hidden;
        overflow-x: hidden;
    }

    &__sort {
        text-align: right;
        padding-bottom: 10px;
        flex-basis: 1px;
        flex-grow: 1;

        @media #{$tablet-up} {
            flex-basis: auto;
        }
    }

    &__sort-text {
        font-weight: 700;
        font-size: 12px;
        text-transform: uppercase;
    }

    &__bar {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 1px 0;
        margin-top: -10px;

        &:last-child {
            padding-top: 15px;
        }

        @media #{$tablet-up} {
            flex-wrap: nowrap;
            margin-top: 0;
        }
    }

    &__bar-item {
        margin-top: 10px;
        flex-grow: 1;

        @media #{$tablet-up} {
            margin-top: 0;
            width: 100%;
        }
    }

    &__counter {
        flex-basis: 1px;
        flex-grow: 1;
        @media #{$tablet-up} {
            flex-basis: auto;
        }
    }

    &__pagination {
        flex-grow: 1;
        text-align: center;
        order: -1;
        width: 100%;

        &:not(:empty) {
            margin-bottom: 20px;

            @media #{$phone-lg-up} {
                margin-bottom: 35px;
            }
            @media #{$tablet-up} {
                margin-bottom: 0;
            }
        }

        @media #{$tablet-up} {
            order: 0;
            width: auto;
            flex-shrink: 0;
        }
    }

    &__load-button {
        margin: 30px auto 20px auto;
    }

    &__loader-wrapper {
        margin-bottom: -20px;
    }
}