.#{$namespace}button {
    border: 0;
    border-radius: 99999px;
    font-weight: 500;
    padding: 10px 20px;
    transition: background-color .1s linear;
    cursor: pointer;
    position: relative;
    text-transform: uppercase;
    color: #fff;
    background-color: $color-dark-grey;
    text-decoration: none;

    &:focus {
        outline: none;
    }

    &--search {
        width: 205px;
        text-align: left;
        font-size: 13px;
        letter-spacing: 2px;
        line-height: 41px;
        padding: 10px 50px 10px 33px;

        @media #{$phone-lg-only} {
            width: 250px;
            font-size: 16px;
            letter-spacing: 2px;
            line-height: 36px;
            padding: 25px 60px 25px 43px;

        }


        & .#{$namespace}icon {
            position: absolute;
            top: 0;
            right: 18px;
            width: 26px;
            height: 100%;
            fill: $color-white;


            @media #{$phone-lg-only} {
                right: 34px;
                width: 36px;
            }
        }
    }
}