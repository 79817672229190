.#{$namespace}widget-topjobs {
    height: 100%;
    padding: 0;
    $root: &;

    .#{$namespace}widget-results__results {
        background: transparent;
    }

    .#{$namespace}widget-results__loader-wrapper {
        margin-bottom: 0;
    }

    .#{$namespace}result {
        margin: 0;

        &:first-child {
            border-top: none;
        }

        &__link {
            margin-bottom: 0;
            padding: 15px 10px;
            border-width: 4px;
            border-style: solid;
            background: $color-white;

            @media #{$tablet-up} {
                padding: 20px 15px;
            }

            &:before {
                display: none;
            }
        }

        &__title {
            font-size: 16px;
            word-wrap: break-word;

            @supports (word-break: break-word) {
                word-break: break-word;
            }

            @media #{$tablet-up} {
                font-size: 21px;
            }
        }

        &__column {
            &:empty {
                display: none;
            }
        }

        &__apply-button-wrapper {
            display: none;
        }
    }

    &--list {
        .#{$namespace}result {
            margin: 20px 0;
            font-size: 13px;
            transition: background-color .2s linear;

            @media #{$tablet-up} {
                font-size: 15px;
            }

            &:first-child .#{$namespace}result__link {
                padding-top: 15px;

                @media #{$tablet-up} {
                    padding-top: 10px;
                }
            }

            &__link {
                @media #{$phone-lg-up} {
                    padding-left: 44px;
                }

                @media #{$tablet-up} {
                    display: flex;
                    align-items: center;
                    flex-wrap: nowrap;
                    padding: 10px 15px 10px 52px;
                }
            }

            &__row {
                display: block;
                width: 100%;

                &--main-info {
                    @media #{$tablet-up} {
                        margin-bottom: 0;
                    }
                }
            }

            &__column {
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                @media #{$phone-lg-up} {
                    flex-wrap: nowrap;
                }
            }

            &__date {
                width: 100%;

                @media #{$phone-lg-up} {
                    float: right;
                    width: auto;
                }

                @media #{$tablet-up} {
                    float: none;
                    order: 1;
                }
            }

            &__title {
                width: 100%;
                padding: 0 20px 0 30px;
                display: flex;
                align-items: center;
                flex-shrink: 0;
                box-sizing: border-box;
                margin-bottom: 5px;

                @media #{$phone-lg-up} {
                    width: 60%;
                    margin-bottom: 0;
                }

                .#{$namespace}icon {
                    flex-shrink: 0;
                }
            }

            &__location-details {
                display: flex;
                flex-wrap: wrap;
                position: relative;
                padding-left: 30px;

                .#{$namespace}icon {
                    position: absolute;
                    flex-shrink: 0;
                }
            }

            & #{$root}__trophy-icon {
                position: absolute;
                top: 0;
                left: 10px;

                & .#{$namespace}icon {
                    width: 25px;
                    height: 30px;

                    @media #{$tablet-up} {
                        width: 33px;
                        height: 40px;
                    }
                }
            }
        }
    }

    &--carousel {
        .#{$namespace}result {
            display: flex;
            min-height: 125px;

            @media #{$tablet-up} {
                min-height: 165px;
            }

            &:first-child .#{$namespace}result__link {
                padding-top: 15px;

                @media #{$tablet-up} {
                    padding-top: 15px;
                }
            }

            &__link {
                box-sizing: border-box;
                padding: 15px 10px 35px 10px;
                flex-grow: 1;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                max-width: calc(100% - 10px);
                border: none;

                @media #{$tablet-up} {
                    padding: 15px 10px 45px 10px;
                }
            }

            &__row {
                box-sizing: border-box;
                flex-direction: column;
                align-items: stretch;
                flex-grow: 1;
                max-width: 100%;

                &--main-info {
                    flex-grow: 1;
                    padding: 0 40px;

                    @media #{$phone-lg-up} {
                        padding: 20px 120px 0 40px;
                    }

                    @media #{$tablet-up} {
                        padding: 30px 130px 0 40px;
                    }
                }
            }

            &__column {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                flex-wrap: wrap;
                flex-basis: auto;
                flex-direction: column;
                max-width: 100%;
                overflow: hidden;
            }

            &__title {
                width: 100%;
                box-sizing: border-box;

                @media #{$phone-xs-only} {
                    font-size: 14px;
                }
            }

            &__date {
                font-size: 14px;

                @media #{$phone-lg-up} {
                    position: absolute;
                    top: 35px;
                    right: 50px;
                }

                @media #{$tablet-up} {
                    top: 45px;
                    font-size: 16px;
                }
            }

            &__location-details {
                font-size: 12px;
                align-self: flex-start;

                @media #{$phone-lg-up} {
                    font-size: 14px;
                }

                @media #{$tablet-up} {
                    font-size: 16px;
                }
            }
        }

        & #{$root}__trophy-icon {
            position: absolute;
            top: 0;
            left: 10px;

            & .#{$namespace}icon {
                width: 25px;
                height: 30px;

                @media #{$tablet-up} {
                    width: 33px;
                    height: 40px;
                }
            }
        }
    }

    &--tiles {
        /*.#{$namespace}widget__container {
            overflow: hidden;
        }*/

        .#{$namespace}widget-results__results {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 2 * -10px -10px 0 -10px;

            @media #{$tablet-up} {
                margin: 2 * -10px -20px;
            }
        }

        .#{$namespace}result {
            padding: 2 * 10px 10px 0 10px;
            display: flex;
            align-items: stretch;
            overflow: hidden;
            width: 100%;
            box-sizing: border-box;
            font-size: 13px;

            @media #{$phone-lg-up} {
                width: 50%;
            }

            @media #{$tablet-up} {
                padding: 2 * 10px 20px;
                width: 33%;
            }

            &__link {
                width: 100%;
                display: flex;
                flex-direction: column;
                overflow: hidden;
                box-sizing: border-box;
            }

            &__row {
                align-items: stretch;
                flex-wrap: wrap;

                &--main-info {
                    padding-top: 30px;
                    flex-grow: 1;
                }
            }

            &__column {
                width: 100%;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            &__date {
                width: 100%;
                text-align: left;
                position: absolute;
                margin: 0;
            }

            &__title {
                display: flex;

                @media #{$phone-lg-up} {
                    font-size: 18px;
                }

                .#{$namespace}icon {
                    flex-shrink: 0;
                }
            }

            &__location-details {

                .#{$namespace}icon {
                    flex-shrink: 0;
                }
            }

            &__hidden-fields {
                display: none;
            }
        }

        & #{$root}__trophy-icon {
            position: absolute;
            top: 0;
            right: 10px;

            & .#{$namespace}icon {
                width: 33px;
                height: 40px;
            }
        }
    }

    &--newsticker {
        .#{$namespace}widget__container {
            max-width: none;
            overflow: hidden;
        }

        .#{$namespace}result {
            font-size: 13px;
            transition: background-color .2s linear;
            margin: 0 5px;

            @media #{$tablet-up} {
                font-size: 15px;
            }

            &:first-child .#{$namespace}result__link {
                padding-top: 5px;

                @media #{$tablet-up} {
                    padding-top: 10px;
                }
            }

            &__link {
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
                padding: 5px 15px 5px 48px;

                @media #{$tablet-up} {
                    padding: 10px 15px 10px 60px;
                }
            }

            &__row {
                display: block;
                flex-shrink: 0;
                margin-bottom: 0;
            }

            &__column {
                display: flex;
                align-items: center;
            }

            &__date {
                order: 1;
                margin-left: 10px;
            }

            &__title {
                padding-right: 20px;
                padding-bottom: 0;
                display: flex;
                align-items: center;

                .#{$namespace}icon {
                    flex-shrink: 0;
                }
            }

            &__location-details {
                display: flex;
                flex-wrap: wrap;

                .#{$namespace}icon {
                    flex-shrink: 0;
                }
            }

            & #{$root}__trophy-icon {
                position: absolute;
                top: 0;
                left: 10px;

                & .#{$namespace}icon {
                    width: 25px;
                    height: 30px;

                    @media #{$tablet-up} {
                        width: 33px;
                        height: 40px;
                    }
                }
            }
        }

        .#{$namespace}widget-results__loader-wrapper {
            margin-bottom: 0;
        }
    }
}