.#{$namespace}color {
    &--on-hover {
        .no-touch &:hover {
            color: $color-orange;
            fill: $color-orange;
        }
    }

    &--primary {
        color: $color-orange;
        fill: $color-orange;

        & .#{$namespace}icon {
            fill: $color-orange;
        }
    }

    &--primary-fill {
        fill: $color-orange;
    }

    &--background {
        background-color: $color-orange;
    }

    &--border {
        border-color: $color-orange;
    }

    &--border-focus {
        &:focus, &.focus {
            border-color: $color-orange;
        }
    }

    &--border-before {
        &:before {
            background: $color-orange;
        }
    }
    &-parent--primary {
        & ~ .cs-icon {
            color: $color-orange;
            fill: $color-orange;
        }
    }
    &-parent--primary-focus {
        &:focus {
            ~ .cs-icon {
                color: $color-orange;
                fill: $color-orange;
            }
        }
    }
    &-parent--primary-hover {
        .no-touch &:hover {
            & .cs-icon {
                color: $color-orange;
                fill: $color-orange;
            }
        }
    }
}

.#{$namespace}color-secondary {
    &--background {
        background-color: $color-orange;
    }

    &--on-hover {
        .no-touch &:hover {
            color: $color-orange;
            fill: $color-orange;
        }
    }
    &--border-hover {
        .no-touch &:hover {
            border-color: $color-orange;
        }
    }
}