.#{$namespace}dropdown {
  position: relative;

  &__list {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 999;
  }
}