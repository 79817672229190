.#{$namespace}modal {
    display: none;
    z-index: -10;
    opacity: 0;
    transition: background-color .3s linear, opacity .2s linear;

    &--open {
        display: block;
        opacity: 1;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparentize(rgb(225, 225, 225), 0.3);
        z-index: 100;
    }

    &__wrapper {
        width: 80%;
        height: calc(100% - 2 * 20px);
        max-width: 1024px;
        position: relative;
        background: $color-white;
        box-shadow: -10px 10px 0 0 rgba(0, 0, 0, 0.17);
        margin: 20px auto;
    }

    &__frame-wrapper {
        width: 100%;
        height: 100%;
        -webkit-overflow-scrolling: touch;
        overflow: scroll;

        .no-touch &, &--no-overflow {
            overflow: hidden;
        }
    }

    &__frame {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        border: 2px solid $color-light-grey;
    }

    &__close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 35px;
        height: 35px;
        margin: 0;
        padding: 0;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        background: mix($color-light-grey, $color-white, 50);
        transition: background-color .2s linear;
        z-index: 100;

        @media #{$phone-lg-up} {
            width: 45px;
            height: 45px;
        }

        @media #{$tablet-up} {
            width: 61px;
            height: 61px;
        }
        .no-touch &:hover {
            opacity: 1;
            background: $color-orange;

            .#{$namespace}icon {
                fill: $color-white;
            }
        }

        &:focus {
            outline: none;
        }

        & .#{$namespace}icon {
            width: 30%;
            fill: $color-base;
        }
    }

    &__nav-buttons {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: -1;
    }

    &__nav-button {
        position: relative;
        width: 10vw;
        height: 20vw;
        max-width: 128px;
        border: none;
        z-index: 101;
        margin: 0 -10vw;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: .7;
        background: none;
        transition: opacity .1s linear, background-color .2s linear;

        &:focus {
            outline: none;
        }

        &--prev {
            left: 0;
        }

        &--next {
            right: 0;
        }

        &--active {
            cursor: pointer;
            background: $color-light-grey;

            .no-touch &:hover {
                opacity: 1;
                background: $color-orange;

                .#{$namespace}icon {
                    fill: $color-white;
                }
            }
        }

        & .#{$namespace}icon {
            width: 12px;
            height: 100%;
            fill: $color-dark-grey;
        }
    }
}