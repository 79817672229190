.#{$namespace}field-label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: $color-white;
    box-sizing: border-box;
    z-index: 9;
    display: none;
    overflow: hidden;
    text-overflow: ellipsis;
}