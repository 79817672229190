.#{$namespace}widget-advanced {
    width: 100%;

    &__container {
        position: relative;
        display: flex;
        flex-direction: column;

        &--results {
            padding-top: 20px;
            @media #{$tablet-up} {
                padding-top: 35px;
            }
        }
    }

    &__suggestions {
        padding-top: 20px;
    }

    &__suggestion-text {
        padding: 20px 0;
        margin: 0 20px;
    }

}