.#{$namespace}icon {
    width: 30px;
    height: 30px;
    display: inline-block;
    transition: fill .1s linear;
    fill: $color-light-grey;
    $root: &;

    &--full {
        width: 100%;
        height: 100%;
    }

    &--social {
        @extend #{$root}--full;
    }

}