/**
 * These atom is responsible for setting aprropriate :before content to body
 * so that its JS counterpart can read and return current breakpoint to other
 * scripts.
 */
body:before {
    content: 'phone-xs';
    display: none; /* Prevent from displaying. */

    @media #{$phone-up} {
        content: 'phone';
    }

    @media #{$phone-lg-up} {
        content: 'phone-lg';
    }

    @media #{$tablet-up} {
        content: 'tablet';
    }

    @media #{$laptop-up} {
        content: 'laptop';
    }

    @media #{$desktop-up} {
        content: 'desktop';
    }

    @media #{$tv-up} {
        content: 'tv';
    }
}
